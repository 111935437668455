import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { environment } from './environments/environment';

// Initial localization
const initialLocalization = `en-XZ`;

// Backend
const backend = {
  loadPath: `${environment.TRANSLATION_DOMAIN}/translation/{{lng}}.json`,
  allowMultiLoading: true,
};

i18next.use(I18NextHttpBackend).use(initReactI18next).init({
  lng: initialLocalization,
  fallbackLng: 'en-XZ',
  load: 'currentOnly',
  partialBundledLanguages: true,
  backend,
});
